var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form" },
    [
      _c(
        "b-row",
        [
          _vm.useVehicleName
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "3" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", { attrs: { for: "email" } }, [
                          _vm._v(_vm._s(_vm.FormMSG(1228, "Name"))),
                        ]),
                        _vm.mandatoryVehicleName
                          ? _c("span", { staticClass: "required" }, [
                              _vm._v(" *"),
                            ])
                          : _vm._e(),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.FormMSG(1229, "Vehicle name ..."),
                          state: !_vm.$v.dataToSend.name.$error,
                        },
                        on: { change: _vm.emitChange },
                        model: {
                          value: _vm.$v.dataToSend.name.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.dataToSend.name, "$model", $$v)
                          },
                          expression: "$v.dataToSend.name.$model",
                        },
                      }),
                      _vm.$v.dataToSend.name.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(125, "Name is required")) +
                                "\n\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.useVehicleBrand
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "3" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", { attrs: { for: "email" } }, [
                          _vm._v(_vm._s(_vm.FormMSG(1230, "Brand"))),
                        ]),
                        _vm.mandatoryVehicleBrand
                          ? _c("span", { staticClass: "required" }, [
                              _vm._v(" *"),
                            ])
                          : _vm._e(),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.FormMSG(1231, "Vehicle brand ..."),
                        },
                        on: { change: _vm.emitChange },
                        model: {
                          value: _vm.dataToSend.brand,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "brand", $$v)
                          },
                          expression: "dataToSend.brand",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.useVehicleModel
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "3" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", { attrs: { for: "email" } }, [
                          _vm._v(_vm._s(_vm.FormMSG(1232, "Model"))),
                        ]),
                        _vm.mandatoryVehicleModel
                          ? _c("span", { staticClass: "required" }, [
                              _vm._v(" *"),
                            ])
                          : _vm._e(),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.FormMSG(1233, "Vehicle model ..."),
                        },
                        on: { change: _vm.emitChange },
                        model: {
                          value: _vm.dataToSend.model,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "model", $$v)
                          },
                          expression: "dataToSend.model",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.useVehiclePlateNumber
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "3" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", { attrs: { for: "email" } }, [
                          _vm._v(_vm._s(_vm.FormMSG(1234, "Number plate"))),
                        ]),
                        _vm.mandatoryVehiclePlateNumber
                          ? _c("span", { staticClass: "required" }, [
                              _vm._v(" *"),
                            ])
                          : _vm._e(),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.FormMSG(
                            1235,
                            "Your number plate ..."
                          ),
                        },
                        on: { change: _vm.emitChange },
                        model: {
                          value: _vm.dataToSend.numberPlate,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "numberPlate", $$v)
                          },
                          expression: "dataToSend.numberPlate",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _vm.useVehicleTransportationMean
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "9" } },
                [
                  _c("transport-type-selector", {
                    ref: "transportTypSelectorVehicule",
                    attrs: {
                      "data-additional-detail":
                        _vm.dataToSend.additionalSubType,
                      "data-detail": _vm.dataToSend.subType,
                      "data-type": _vm.dataToSend.type,
                      "hide-boat-type": "",
                      "hide-menues-not-two-wheels": "",
                      "hide-freight-type": "",
                      "hide-special-vehicles": "",
                      "mandatory-vehicule-transportation-mean":
                        _vm.mandatoryVehicleTransportationMean,
                    },
                    on: {
                      "transportation-type-selector:change":
                        _vm.handleTransportationTypeSelectorChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.useVehicleKgCoTwo
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "3" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("template", { slot: "label" }, [
                        _c("label", [
                          _vm._v(
                            _vm._s(_vm.FormMSG(1238, "Kg CO2 per")) +
                              " " +
                              _vm._s(_vm.distanceUnit)
                          ),
                        ]),
                        _vm.mandatoryVehicleKgCoTwo
                          ? _c("span", { staticClass: "required" }, [
                              _vm._v(" *"),
                            ])
                          : _vm._e(),
                      ]),
                      _c("b-form-input", {
                        attrs: {
                          type: "number",
                          step: "0.01",
                          min: "0",
                          placeholder: "0",
                        },
                        on: { change: _vm.emitChange },
                        model: {
                          value: _vm.dataToSend.kgCoTwoPerKm,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "kgCoTwoPerKm", $$v)
                          },
                          expression: "dataToSend.kgCoTwoPerKm",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _vm.usePower
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.FormMSG(799, "Power")) + "\n\t\t\t\t\t"
                        ),
                        _vm.mandatoryPower
                          ? _c("span", { staticClass: "required" }, [
                              _vm._v(" *"),
                            ])
                          : _vm._e(),
                      ]),
                      _c("b-form-input", {
                        attrs: { type: "number", step: "0.01", min: "0" },
                        on: { change: _vm.emitChange },
                        model: {
                          value: _vm.dataToSend.taxHorsepower,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "taxHorsepower", $$v)
                          },
                          expression: "dataToSend.taxHorsepower",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.useGrayCardFrontPicture
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.FormMSG(863, "Gray card front picture")) +
                            "\n\t\t\t\t\t"
                        ),
                        _vm.mandatoryGrayCardFrontPicture
                          ? _c("span", { staticClass: "required" }, [
                              _vm._v(" *"),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "ml-0" },
                        [
                          _vm.checkFile(_vm.dataToSend.grayCardXid)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      _vm.onOpenUrl(
                                        _vm.getImage(
                                          _vm.dataToSend.grayCardXid,
                                          "grayCardXid"
                                        )
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "file-earmark-pdf-fill",
                                      "font-scale": "2",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "b-img",
                                _vm._b(
                                  {
                                    staticClass: "cursor-pointer",
                                    attrs: {
                                      rounded: "",
                                      alt: "Driver license picture - Front",
                                      src: _vm.getImage(
                                        _vm.dataToSend.grayCardXid,
                                        "grayCardXid"
                                      ),
                                      title: _vm.FormMSG(254, "View Image"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showImage(
                                          _vm.dataToSend.grayCardXid
                                        )
                                      },
                                    },
                                  },
                                  "b-img",
                                  _vm.configImgGrayCardXid,
                                  false
                                )
                              ),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: { variant: "outline-secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.startCapture("grayCardXid")
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center",
                                },
                                [
                                  _c(_vm.getLucideIcon("Camera"), {
                                    tag: "component",
                                    attrs: { size: 20 },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pl-2",
                                      staticStyle: { "margin-bottom": "-1px" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(220, "Picture"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.dataToSend.grayCardXid &&
                          _vm.dataToSend.grayCardXid.length > 0
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn-transparent pl-0 ml-2",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.deleteImg(
                                        _vm.dataToSend.grayCardXid,
                                        "grayCardXid"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(_vm.getLucideIcon("Trash2"), {
                                    tag: "component",
                                    attrs: {
                                      size: 24,
                                      "stroke-width": 1.5,
                                      color: "#ea4e2c",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.mandatoryGrayCardFrontPicture &&
                          _vm.dataToSend.grayCardXid.length === 0
                            ? _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName:
                                            "v-b-tooltip.hover.bottom.html",
                                          value: _vm.FormMSG(
                                            800,
                                            "Gray card front picture empty"
                                          ),
                                          expression:
                                            "FormMSG(800, 'Gray card front picture empty')",
                                          modifiers: {
                                            hover: true,
                                            bottom: true,
                                            html: true,
                                          },
                                        },
                                      ],
                                      staticClass:
                                        "btn bg-transparent border-0",
                                      attrs: { size: "sm" },
                                    },
                                    [
                                      _c(_vm.getIconLucide("AlertTriangle"), {
                                        tag: "component",
                                        attrs: { color: "#ffc107", size: 30 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.useGrayCardBackPicture
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.FormMSG(862, "Gray card back picture")) +
                            "\n\t\t\t\t\t"
                        ),
                        _vm.mandatoryGrayCardBackPicture
                          ? _c("span", { staticClass: "required" }, [
                              _vm._v(" *"),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "ml-0" },
                        [
                          _vm.checkFile(_vm.dataToSend.grayCardVersoXid)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      _vm.onOpenUrl(
                                        _vm.getImage(
                                          _vm.dataToSend.grayCardVersoXid,
                                          "grayCardVersoXid"
                                        )
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "file-earmark-pdf-fill",
                                      "font-scale": "2",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "b-img",
                                _vm._b(
                                  {
                                    staticClass: "cursor-pointer",
                                    attrs: {
                                      rounded: "",
                                      alt: "Driver license picture - Front",
                                      src: _vm.getImage(
                                        _vm.dataToSend.grayCardVersoXid,
                                        "grayCardVersoXid"
                                      ),
                                      title: _vm.FormMSG(254, "View Image"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showImage(
                                          _vm.dataToSend.grayCardVersoXid
                                        )
                                      },
                                    },
                                  },
                                  "b-img",
                                  _vm.configImgGrayCardVersoXid,
                                  false
                                )
                              ),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: { variant: "outline-secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.startCapture("grayCardVersoXid")
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center",
                                },
                                [
                                  _c(_vm.getLucideIcon("Camera"), {
                                    tag: "component",
                                    attrs: { size: 20 },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pl-2",
                                      staticStyle: { "margin-bottom": "-1px" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.FormMSG(220, "Picture"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.dataToSend.grayCardVersoXid &&
                          _vm.dataToSend.grayCardVersoXid.length > 0
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn-transparent pl-0 ml-2",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.deleteImg(
                                        _vm.dataToSend.grayCardVersoXid,
                                        "grayCardVersoXid"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(_vm.getLucideIcon("Trash2"), {
                                    tag: "component",
                                    attrs: {
                                      size: 24,
                                      "stroke-width": 1.5,
                                      color: "#ea4e2c",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.mandatoryGrayCardBackPicture &&
                          _vm.dataToSend.grayCardVersoXid.length === 0
                            ? _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName:
                                            "v-b-tooltip.hover.bottom.html",
                                          value: _vm.FormMSG(
                                            801,
                                            "Gray card back picture empty"
                                          ),
                                          expression:
                                            "FormMSG(801, 'Gray card back picture empty')",
                                          modifiers: {
                                            hover: true,
                                            bottom: true,
                                            html: true,
                                          },
                                        },
                                      ],
                                      staticClass:
                                        "btn bg-transparent border-0",
                                      attrs: { size: "sm" },
                                    },
                                    [
                                      _c(_vm.getIconLucide("AlertTriangle"), {
                                        tag: "component",
                                        attrs: { color: "#ffc107", size: 30 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("NewCapture", {
        key: _vm.refreshTokenCaptureItem,
        attrs: {
          parentType: "vehicle",
          parentId: 0,
          "auto-crop-capture": "",
          "cam-mode-first": "",
          "only-one-crop-mode": "",
        },
        on: { change: _vm.handleImgsTaked },
        model: {
          value: _vm.capturePict,
          callback: function ($$v) {
            _vm.capturePict = $$v
          },
          expression: "capturePict",
        },
      }),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(253, "Picture"),
            "ok-variant": "success",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.openPictureModal = false
            },
          },
          model: {
            value: _vm.openPictureModal,
            callback: function ($$v) {
              _vm.openPictureModal = $$v
            },
            expression: "openPictureModal",
          },
        },
        [
          _c(
            "div",
            [_c("b-img", { attrs: { src: _vm.imgToShow, "fluid-grow": "" } })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }