<template>
	<div class="form">
		<b-row>
			<b-col v-if="useVehicleName" sm="12" md="3">
				<b-form-group>
					<template slot="label">
						<label for="email">{{ FormMSG(1228, 'Name') }}</label>
						<span class="required" v-if="mandatoryVehicleName"> *</span>
					</template>
					<b-form-input
						v-model="$v.dataToSend.name.$model"
						type="text"
						:placeholder="FormMSG(1229, 'Vehicle name ...')"
						:state="!$v.dataToSend.name.$error"
						@change="emitChange"
					/>
					<div v-if="$v.dataToSend.name.$error" class="invalid-feedback">
						{{ FormMSG(125, 'Name is required') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col v-if="useVehicleBrand" sm="12" md="3">
				<b-form-group>
					<template slot="label">
						<label for="email">{{ FormMSG(1230, 'Brand') }}</label>
						<span class="required" v-if="mandatoryVehicleBrand"> *</span>
					</template>
					<b-form-input v-model="dataToSend.brand" type="text" :placeholder="FormMSG(1231, 'Vehicle brand ...')" @change="emitChange" />
				</b-form-group>
			</b-col>
			<b-col v-if="useVehicleModel" sm="12" md="3">
				<b-form-group>
					<template slot="label">
						<label for="email">{{ FormMSG(1232, 'Model') }}</label>
						<span class="required" v-if="mandatoryVehicleModel"> *</span>
					</template>
					<b-form-input v-model="dataToSend.model" type="text" :placeholder="FormMSG(1233, 'Vehicle model ...')" @change="emitChange" />
				</b-form-group>
			</b-col>
			<b-col v-if="useVehiclePlateNumber" sm="12" md="3">
				<b-form-group>
					<template slot="label">
						<label for="email">{{ FormMSG(1234, 'Number plate') }}</label>
						<span class="required" v-if="mandatoryVehiclePlateNumber"> *</span>
					</template>
					<b-form-input v-model="dataToSend.numberPlate" type="text" :placeholder="FormMSG(1235, 'Your number plate ...')" @change="emitChange" />
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col v-if="useVehicleTransportationMean" sm="12" md="9">
				<transport-type-selector
					ref="transportTypSelectorVehicule"
					:data-additional-detail="dataToSend.additionalSubType"
					:data-detail="dataToSend.subType"
					:data-type="dataToSend.type"
					hide-boat-type
					hide-menues-not-two-wheels
					hide-freight-type
					hide-special-vehicles
					@transportation-type-selector:change="handleTransportationTypeSelectorChange"
					:mandatory-vehicule-transportation-mean="mandatoryVehicleTransportationMean"
				/>
			</b-col>
			<b-col v-if="useVehicleKgCoTwo" sm="12" md="3">
				<b-form-group>
					<template slot="label">
						<label>{{ FormMSG(1238, 'Kg CO2 per') }} {{ distanceUnit }}</label>
						<span class="required" v-if="mandatoryVehicleKgCoTwo"> *</span>
					</template>
					<b-form-input v-model="dataToSend.kgCoTwoPerKm" type="number" step="0.01" min="0" placeholder="0" @change="emitChange" />
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col v-if="usePower" sm="12" md="3" lg="3" xl="3">
				<b-form-group>
					<label
						>{{ FormMSG(799, 'Power') }}
						<span class="required" v-if="mandatoryPower"> *</span>
					</label>
					<b-form-input type="number" step="0.01" min="0" v-model="dataToSend.taxHorsepower" @change="emitChange" />
				</b-form-group>
			</b-col>
			<b-col v-if="useGrayCardFrontPicture" sm="12" md="3" lg="3" xl="3">
				<b-form-group>
					<label
						>{{ FormMSG(863, 'Gray card front picture') }}
						<span class="required" v-if="mandatoryGrayCardFrontPicture"> *</span>
					</label>
					<b-row class="ml-0">
						<div
							@click="onOpenUrl(getImage(dataToSend.grayCardXid, 'grayCardXid'))"
							class="d-flex justify-content-center align-items-center cursor-pointer"
							v-if="checkFile(dataToSend.grayCardXid)"
						>
							<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
						</div>
						<b-img
							v-else
							v-bind="configImgGrayCardXid"
							rounded
							alt="Driver license picture - Front"
							class="cursor-pointer"
							:src="getImage(dataToSend.grayCardXid, 'grayCardXid')"
							@click="showImage(dataToSend.grayCardXid)"
							:title="FormMSG(254, 'View Image')"
						/>
						<b-button variant="outline-secondary" class="ml-2" @click="startCapture('grayCardXid')">
							<div class="d-flex justify-content-center align-items-center">
								<component :is="getLucideIcon('Camera')" :size="20" />
								<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
							</div>
						</b-button>
						<button
							class="btn-transparent pl-0 ml-2"
							@click.prevent.stop="deleteImg(dataToSend.grayCardXid, 'grayCardXid')"
							v-if="dataToSend.grayCardXid && dataToSend.grayCardXid.length > 0"
						>
							<component :is="getLucideIcon('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
						</button>
						<div class="d-flex align-items-center" v-if="mandatoryGrayCardFrontPicture && dataToSend.grayCardXid.length === 0">
							<b-button
								size="sm"
								class="btn bg-transparent border-0"
								v-b-tooltip.hover.bottom.html="FormMSG(800, 'Gray card front picture empty')"
							>
								<component :is="getIconLucide('AlertTriangle')" color="#ffc107" :size="30" />
							</b-button>
						</div>
					</b-row>
				</b-form-group>
			</b-col>
			<b-col v-if="useGrayCardBackPicture" sm="12" md="3" lg="3" xl="3">
				<b-form-group>
					<label
						>{{ FormMSG(862, 'Gray card back picture') }}
						<span class="required" v-if="mandatoryGrayCardBackPicture"> *</span>
					</label>
					<b-row class="ml-0">
						<div
							@click="onOpenUrl(getImage(dataToSend.grayCardVersoXid, 'grayCardVersoXid'))"
							class="d-flex justify-content-center align-items-center cursor-pointer"
							v-if="checkFile(dataToSend.grayCardVersoXid)"
						>
							<b-icon icon="file-earmark-pdf-fill" font-scale="2" />
						</div>
						<b-img
							v-else
							v-bind="configImgGrayCardVersoXid"
							rounded
							alt="Driver license picture - Front"
							class="cursor-pointer"
							:src="getImage(dataToSend.grayCardVersoXid, 'grayCardVersoXid')"
							@click="showImage(dataToSend.grayCardVersoXid)"
							:title="FormMSG(254, 'View Image')"
						></b-img>
						<b-button variant="outline-secondary" class="ml-2" @click="startCapture('grayCardVersoXid')">
							<div class="d-flex justify-content-center align-items-center">
								<component :is="getLucideIcon('Camera')" :size="20" />
								<div class="pl-2" style="margin-bottom: -1px">{{ FormMSG(220, 'Picture') }}</div>
							</div>
						</b-button>
						<button
							class="btn-transparent pl-0 ml-2"
							@click.prevent.stop="deleteImg(dataToSend.grayCardVersoXid, 'grayCardVersoXid')"
							v-if="dataToSend.grayCardVersoXid && dataToSend.grayCardVersoXid.length > 0"
						>
							<component :is="getLucideIcon('Trash2')" :size="24" :stroke-width="1.5" color="#ea4e2c" />
						</button>
						<div class="d-flex align-items-center" v-if="mandatoryGrayCardBackPicture && dataToSend.grayCardVersoXid.length === 0">
							<b-button
								size="sm"
								class="btn bg-transparent border-0"
								v-b-tooltip.hover.bottom.html="FormMSG(801, 'Gray card back picture empty')"
							>
								<component :is="getIconLucide('AlertTriangle')" color="#ffc107" :size="30" />
							</b-button>
						</div>
					</b-row>
				</b-form-group>
			</b-col>
		</b-row>

		<NewCapture
			v-model="capturePict"
			:key="refreshTokenCaptureItem"
			parentType="vehicle"
			:parentId="0"
			auto-crop-capture
			cam-mode-first
			only-one-crop-mode
			@change="handleImgsTaked"
		/>

		<b-modal
			header-class="header-class-modal-doc-package"
			v-model="openPictureModal"
			:title="FormMSG(253, 'Picture')"
			class="modal-success"
			ok-variant="success"
			ok-only
			@ok="openPictureModal = false"
		>
			<div>
				<b-img :src="imgToShow" fluid-grow />
			</div>
		</b-modal>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { store } from '@/store';
import { noTilde } from '~utils';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import TransportTypeSelector from '@/modules/transports/components/TransportTypeSelector.vue';
import NewCapture from '@/components/CaptureModule/Main';
import { addUpdVehicule } from '@/cruds/vehicules.crud';
import * as icons from 'lucide-vue';

const INIT_VEHICULE_DATA = {
	name: null,
	brand: null,
	model: null,
	numberPlate: null,
	type: 6,
	subType: 2,
	additionalSubType: 7,
	energy: null,
	reference: null,
	kgCoTwoPerKm: 0,
	taxHorsepower: 0,
	grayCardXid: '',
	grayCardVersoXid: ''
};
export default {
	name: 'VehiculeForm',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		vehiculeData: { type: Object, default: () => ({}), required: false },
		useVehicleName: { type: Boolean, default: true, required: false },
		useVehicleBrand: { type: Boolean, default: true, required: false },
		useVehicleModel: { type: Boolean, default: true, required: false },
		useVehiclePlateNumber: { type: Boolean, default: true, required: false },
		useVehicleTransportationMean: { type: Boolean, default: true, required: false },
		useVehicleTransportationMeanDetail: { type: Boolean, default: true, required: false },
		useVehicleKgCoTwo: { type: Boolean, default: true, required: false },
		usePower: { type: Boolean, default: true, required: false },
		useGrayCardFrontPicture: { type: Boolean, default: true, required: false },
		useGrayCardBackPicture: { type: Boolean, default: true, required: false },
		mandatoryVehicleName: { type: Boolean, default: false, required: false },
		mandatoryVehicleBrand: { type: Boolean, default: false, required: false },
		mandatoryVehicleModel: { type: Boolean, default: false, required: false },
		mandatoryVehiclePlateNumber: { type: Boolean, default: false, required: false },
		mandatoryVehicleTransportationMean: { type: Boolean, default: false, required: false },
		mandatoryVehicleKgCoTwo: { type: Boolean, default: false, required: false },
		mandatoryPower: { type: Boolean, default: false, required: false },
		mandatoryGrayCardFrontPicture: { type: Boolean, default: false, required: false },
		mandatoryGrayCardBackPicture: { type: Boolean, default: false, required: false }
	},

	components: {
		TransportTypeSelector,
		NewCapture
	},

	data() {
		return {
			dataToSend: _.cloneDeep(INIT_VEHICULE_DATA),
			configImgGrayCardVersoXid: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			configImgGrayCardXid: { blank: true, blankColor: '#d5d6d7', width: 32, height: 32, class: 'm1' },
			openPictureModal: false,
			imgToShow: '',
			capturePict: false,
			currentImgField: 'grayCardXid',
			refreshTokenCaptureItem: 0
		};
	},

	validations: {
		dataToSend: {
			name: { required }
		}
	},

	watch: {
		vehiculeData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend = {
						name: newVal.name,
						brand: newVal.brand,
						model: newVal.model,
						numberPlate: newVal.numberPlate,
						type: newVal.type,
						subType: newVal.subType,
						additionalSubType: newVal.additionalSubType,
						energy: newVal.energy,
						reference: newVal.reference,
						kgCoTwoPerKm: newVal.kgCoTwoPerKm,
						taxHorsepower: newVal.taxHorsepower,
						grayCardXid: newVal.grayCardXid,
						grayCardVersoXid: newVal.grayCardVersoXid
					};
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		vehicleEnergies() {
			let energies = this.FormMenu(10004);

			energies.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return energies;
		},
		vehicleTypes() {
			let types = this.FormMenu(905);

			types = types.filter((option) => [1, 3, 4, 6, 11, 12, 14, 15, 16, 17].includes(option.value));

			types = types.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return types;
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		}
	},

	methods: {
		getIconLucide(name) {
			return icons[name];
		},
		handleImgsTaked(imgs) {
			const retStr = imgs[0];
			this.dataToSend[this.currentImgField] = retStr;
			this.refreshTokenCaptureItem = this.refreshTokenCaptureItem + 1;

			this.emitChange(true);
		},
		startCapture(fieldName) {
			// initialise global store variable and open dialog to capture and crop
			//console.log("instartcapture");
			store.state.capturedImage = '';
			store.state.imageCropped = '';
			this.disableSaveButton = true;
			this.capturePict = true;
			this.currentImgField = fieldName;
		},
		deleteImg(xid, fieldName) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(256, 'Are you sure to remove this picture ?'), {
					title: this.FormMSG(257, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(258, 'YES'),
					cancelTitle: this.FormMSG(259, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;
					await this.$axios.$get(`/removeimage/${xid}`);
					this.dataToSend[fieldName] = '';

					if (!_.isNil(this.vehiculeData.id) && +this.vehiculeData.id > 0) {
						await addUpdVehicule({
							vehiculeId: +this.vehiculeData.id,
							payload: {
								grayCardXid: this.dataToSend.grayCardXid,
								grayCardVersoXid: this.dataToSend.grayCardVersoXid
							}
						});
					}

					if (fieldName === 'grayCardVersoXid') {
						this.configImgGrayCardVersoXid.blank = true;
					} else {
						this.configImgGrayCardXid.blank = true;
					}

					this.emitChange();
				})
				.catch((err) => console.log(err));
		},
		showImage(picture) {
			if (_.isNil(picture) || (!_.isNil(picture) && picture.length === 0)) return;
			this.imgToShow = `${process.env.VUE_APP_GQL}/images/${picture}`;
			this.openPictureModal = true;
		},
		checkFile(file) {
			if (file !== undefined) {
				let extension = file.toString().match(/.pdf/gi);
				if (extension !== null && extension.join`` === '.pdf') {
					return true;
				}
				return false;
			}
			return false;
		},
		onOpenUrl(url) {
			return window.open(url);
		},
		getImage(picture, fieldName) {
			if (!_.isNil(picture) && picture.length > 0) {
				if (fieldName === 'grayCardXid') {
					this.configImgGrayCardXid.blank = false;
				} else {
					this.configImgGrayCardVersoXid.blank = false;
				}
			}

			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		isInvalid() {
			this.$v.$touch();

			const resultIsInvalidTypeTransport = this.$refs['transportTypSelectorVehicule'].isInvalid();

			return this.$v.$invalid === true || resultIsInvalidTypeTransport === true;
		},
		handleTransportationTypeSelectorChange({ type, detail, additionalDetail }) {
			this.dataToSend.type = type;
			this.dataToSend.subType = detail;
			this.dataToSend.additionalSubType = additionalDetail;

			this.emitChange();
		},
		emitChange(isImage = false) {
			this.dataToSend.taxHorsepower = +this.dataToSend.taxHorsepower;
			this.$emit('vehicule-form:change', this.dataToSend, isImage);
		}
	}
};
</script>

<style scoped>
.required {
	color: red;
}
</style>
